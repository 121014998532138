window.$ = $;

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import { Modal } from 'bootstrap';

window.alert = function(message, type = 'danger') {
    let $toast = $(`
        <div class="toast align-items-center text-white bg-${type} border-0" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-body d-flex" data-bs-dismiss="toast">
                <div class='me-2'>
                    ${message}
                </div>
                <button type="button" class="btn-close btn-close-white ms-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    `);
    $('.toast-container').prepend($toast);
    $toast.toast('show');
};

// przenosi pojedyncze znaki z końca linijki na początek następnej
export function addNbsps(el) {
    $(el).find(":not(iframe):not(style):not(script)").add(el).contents().filter((i, n) => n.nodeType == 3).each(function () {
        var startWhitespace = /^\s/.test(this.nodeValue) ? ' ' : '';
        var endWhitespace = /\s$/.test(this.nodeValue) ? ' ' : '';
        this.nodeValue = startWhitespace + (' ' + this.nodeValue + ' ').replace(/(\s\w)\s+/g, "$1\xA0").trim() + endWhitespace;
    });
};

addNbsps('body');

// $('[data-toggle="tooltip"]').tooltip();

$.fn.initJs = function () {
    $(window).trigger('init', this);
    return this;
};
Object.defineProperty($, "init", {
    set(fn) {
        $(window).on('init', function(e, el) {
            fn(el, e);
        });
    }
});
window.addEventListener('DOMContentLoaded', () => $('body').initJs());

// modal

$('.modal').on('show.bs.modal', function() {
    $('html').addClass('disable-scroll');
});

$('.modal').on('hide.bs.modal', function() {
    $('html').removeClass('disable-scroll');
});

// s recommended

let sRecommendedSlider;

$('.s-recommended__slider .swiper').each(function(){

    sRecommendedSlider = new Swiper($(this)[0], {
        slidesPerView: 1,
        speed: 500,
        loop: true,
        simulateTouch: false,
        navigation: {
            prevEl: $(this).siblings('.swiper-button-prev')[0],
            nextEl: $(this).siblings('.swiper-button-next')[0]
        },
        pagination: {
            el: $(this).siblings('.swiper-pagination')[0],
            clickable: true
        },
        modules: [Navigation, Pagination]
    });

});

// s magic

let sMagicSlider;

$('.s-magic__slider .swiper').each(function(){

    sMagicSlider = new Swiper($(this)[0], {
        slidesPerView: 1,
        speed: 500,
        loop: true,
        simulateTouch: false,
        navigation: {
            prevEl: $(this).siblings('.swiper-button-prev')[0],
            nextEl: $(this).siblings('.swiper-button-next')[0]
        },
        pagination: {
            el: $(this).siblings('.swiper-pagination')[0],
            clickable: true
        },
        modules: [Navigation, Pagination]
    });

});

// s corner

let sCornerSlider;

$('.s-corner__slider .swiper').each(function(){

    let that = $(this);

    sCornerSlider = new Swiper(that[0], {
        slidesPerView: 'auto',
        speed: 500,
        loop: true,
        navigation: {
            prevEl: $(this).siblings('.swiper-button-prev')[0],
            nextEl: $(this).siblings('.swiper-button-next')[0]
        },
        modules: [Navigation]
    });

    function toggleSlider(widthWindow) {
        widthWindow > 767 ? that[0].swiper.disable() : that[0].swiper.enable();
    }

    let widthWindow = $(window).outerWidth();

    toggleSlider(widthWindow);

    $(window).resize(function(){

        let widthWindow = $(window).outerWidth();

        toggleSlider(widthWindow);

    });

});

// video

$('.s-recommended__item, .s-magic__item, .s-meet__video').click(function(e){

    e.preventDefault();

    let href = $(this).attr('href'),
        modal = $('.m-video'),
        modalVideo = modal.find('video');

    modalVideo.attr('src', href)[0].play();

});

$('.m-video').on('hidden.bs.modal', function(e) {
    $(this).find('video')[0].pause();
});